import "@babel/polyfill";
// import 'jquery';
import $ from 'jquery';

import 'bootstrap';
import 'bootstrap/js/dist/util';
import '@fortawesome/fontawesome-free/js/all.js';
// import 'magnific-popup/dist/jquery.magnific-popup'
// import 'bootstrap/js/dist/dropdown';
var Luminous = require('luminous-lightbox').Luminous;

import 'justifiedGallery';
var moment = require('moment');
import baguetteBox from 'baguettebox.js';
// import Bricks from 'bricks.js';


$('document').ready(function(){

    baguetteBox.run('.gallery');

    // window.setTimeout(function(){
    //     instance.pack();
    // }, 10)

    // console.log('ready');
    // $("#basicExample").justifiedGallery({
    //
    //     rowHeight: 200,
    //     maxRowHeight: 250,
    //     lastRow: 'nojustify',
    //     margins: 5,
    //     captions: false
    // }).on('jg.complete', function () {
    //     baguetteBox.run('.gallery');
    //
    // });
    // var lightboxLink = document.querySelector('.lightbox');
    // console.log('lightboxLink', lightboxLink);
    // new Luminous(lightboxLink);
});


import Vue from 'vue'
import Components from './components'
import './app.scss'

import Matomo from './components/Matomo'

import VueRouter from 'vue-router';

Vue.use(VueRouter)

Vue.config.productionTip = false

import MatomoLive from './components/MatomoLive';
import MatomoVisitsSummary from './components/MatomoVisitsSummary';
import MatomoParams from './components/MatomoParams';

// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
const routes = [
    { path: '/overview/', component: MatomoOverview },
    { path: '/visits-summary/', component: MatomoVisitsSummary },
    { path: '/live', component: MatomoLive },
    { path: '/report', component: MatomoCustomReport },
    { path: '/report/:reportId', component: MatomoCustomReport },
    { path: '/events', component: MatomoEvents },
    { path: '/params', component: MatomoParams },
]

const router = new VueRouter({
    linkActiveClass: "active",
    routes // short for `routes: routes`

})

// const app = new Vue({
//     router
// }).$mount('#app')
new Vue({
  el: '#app',
  delimiters: ['${', '}'],
    router: router,
})




import instantsearch from "instantsearch.js";
import {hits} from "instantsearch.js/es/widgets";
import {searchBox} from "instantsearch.js/es/widgets";
import {refinementList} from "instantsearch.js/es/widgets";
import {stats} from "instantsearch.js/es/widgets";
import {pagination} from "instantsearch.js/es/widgets";
import {currentRefinedValues} from "instantsearch.js/es/widgets";
import {clearAll} from "instantsearch.js/es/widgets";
import {hitsPerPageSelector} from "instantsearch.js/es/widgets";
import {menuSelect} from "instantsearch.js/es/widgets";
import MatomoCustomReport from "./components/MatomoCustomReport";
import MatomoEvents from "./components/MatomoEvents";
import MatomoOverview from "./components/MatomoOverview";


let logoDeleteButtons = document.querySelectorAll('.js-delete-logo');
logoDeleteButtons.forEach(deleteButton => {
    deleteButton.addEventListener('click', e => {
        e.preventDefault();
        let target = deleteButton.getAttribute('data-target');
        target = document.querySelector(target);
        target.remove();
    });
});



const setupGaTracking = function () {
    let tracked = document.getElementsByClassName('track-event');
    function myFunction() {
        let action = this.getAttribute("data-event-action");
        let category = this.getAttribute("data-event-category");
        let label = this.getAttribute("data-event-label");
        trackEvent(action, category, label);
    }
    for (var i = 0; i < tracked.length; i++) {
        tracked[i].addEventListener('click', myFunction, false);
    }
};

setupGaTracking();

const trackEvent = function(eventAction, eventCategory, eventLabel, assetCategory) {
    if (!assetCategory) {
        assetCategory = eventCategory;
    }
    let matomoEvent = ['trackEvent', eventCategory, eventAction, eventLabel, {dimension3: assetCategory}];
    console.log('matomoEvent', matomoEvent);

    try {
        _paq.push(matomoEvent);
    } catch (e) {
        console.log('Error tracking Matomo event:');
        console.log(e)
    }

    try {
        gtag('event', eventAction, {
            'event_category': eventCategory,
            'event_label': eventLabel,
        });
    } catch (e) {
        console.log('Error tracking Gtag event:');
        console.log(e)
    }
};

var algoliaBrowse = window.algoliaBrowse;

if (algoliaBrowse) {
    // console.log("Setting up Algolia Instantsearch");
    // console.log('algoliaCategories', algoliaAssetTypes);
    // // Remove the last comma to avoid an empty item
    // algoliaCategories = algoliaCategories.slice(0, -1);
    // // Split the string into an array
    // algoliaCategories = algoliaCategories.split(",");
    // console.log('algoliaCategories', algoliaCategories);

    let filters = "";
    // let typeFilters = [];
    // algoliaContentTypes.forEach(function(type){
    //     typeFilters.push("contentType.handle: " + type);
    // });
    // filters += typeFilters.join(' OR ');

    let assetCatFilters = [];
    algoliaAssetCategories.forEach(function(cat){
        assetCatFilters.push("assetCategory.slug: " + cat);
    });
    filters += assetCatFilters.join(' OR ');

    let canViewAll = false;
    if (algoliaCanViewAll) {
        canViewAll = true;
    }

    if (!canViewAll) {
        filters += " AND employeeOnly: false ";
    }

    if (filters) {
        filters += " AND ";
    }

    filters += "NOT assetType.slug: bundle-item AND enabled: 1 OR enabled: true AND allGroups: True ";
    // let filters = "contentType.handle: brochures";
    // let filters = "";
    algoliaTeams.forEach(function(team){
        console.log('team', team);
        filters += " OR teamGroup.slug: " + team;

    });
    console.log('filters', filters);
    const search = instantsearch({
        appId: window.algoliaAppId,
        apiKey: window.algoliaSearchApiKey,
        indexName: window.algoliaIndex,
        routing: true,
        searchParameters: {
            // hierarchicalFacetsRefinements: { // menu is implemented as a hierarchicalFacetsRefinements
            //   categories: ['Cell Phones']
            // },
            // facets: ['contentType', 'teamGroup.slug'],

            filters: filters,

            facetsRefinements: {
                // "contentType.handle": ['brochures'],
                "assetType.slug": algoliaAssetTypes,
                // "teamGroup.slug": ['midwest']
            },
            // Add to "facets" all attributes for which you
            // do NOT have a widget defined
            // facets: ['contentType.handle']
        },
    });


    var elements = document.querySelectorAll('.refinement-list');
    const attributes = [];
    Array.prototype.forEach.call(elements, function (el, i) {
        // console.log('el', el);
        var attributeName = el.getAttribute('data-attribute');
        var attributeLabel = el.getAttribute('data-attribute-label');
        var widget = el.getAttribute('data-attribute-widget');
        var limit = el.getAttribute('data-attribute-limit');
        if (!limit) {
            limit = false;
        }
        var operator = el.getAttribute('data-attribute-operator');
        attributes.push({name: attributeName + '.title', label: attributeLabel});
        attributes[attributeLabel] = attributeLabel;
        // console.log('attributeName', attributeName);
        if (widget == 'select') {
            let args = {
                container: '#refinement-list-' + attributeName,
                attributeName: attributeName + '.title',
                templates: {
                    header: attributeLabel,
                },
                limit: limit,
                showMore: true,
                sortBy: ['name:asc'],
                cssClasses: {
                    label: 'form-check-label',
                    select: 'form-control custom-select'
                }
            };
            search.addWidget(
                menuSelect(args)
            );
        }
        if (widget == 'checkboxes') {
            search.addWidget(
                refinementList({
                    container: '#refinement-list-' + attributeName,
                    attributeName: attributeName + '.title',
                    templates: {
                      header: attributeLabel,
                    },
                    operator: operator,
                    limit: limit,
                    showMore: true,
                    sortBy: ['name:asc'],
                    cssClasses: {
                        label: 'form-check-label',
                        count: 'badge badge-primary'
                    }
                })
            );
        }

    });
    // console.log('attributes', attributes);


    // initialize RefinementList


    let searchEl = document.getElementById("search-box");
       if(searchEl){
           search.addWidget(
             searchBox({

               container: '#search-box',
               placeholder: 'Search...',
                 templates: {
                     header: "Search!",
                 },
                 cssClasses: {
                     input: 'form-control',
                 }

             })
           );
       }

    let statsTopEl = document.getElementById("stats-top");
    if (statsTopEl) {
        search.addWidget(
            stats({
                container: '#stats-top',
            })
        );
    }


    search.addWidget(
        stats({
            container: '#stats',
        })
    );

    // search.addWidget(
    //     currentRefinedValues({
    //         container: '#currentRefinedValues',
    //         attributes: attributes,
    //         onlyListedAttributes: true,
    //         clearAll: false,
    //         cssClasses: {
    //             count: 'badge badge-secondary',
    //             link: 'btn btn-secondary btn-sm'
    //         }
    //     })
    // );
    // search.addWidget(
    //     clearAll({
    //         container: '#clearAll',
    //         attributes: attributes,
    //         // onlyListedAttributes: false,
    //         excludeAttributes: ['contentType.handle'],
    //         cssClasses: {
    //             link: 'btn btn-link',
    //         }
    //     })
    // );
    let hitsPerPageEl = document.getElementById("hitsPerPageSelector");
    if (hitsPerPageEl) {
        search.addWidget(
            hitsPerPageSelector({
                container: '#hitsPerPageSelector',
                items: [
                    {value: 18, label: '18 per page', default: true},
                    {value: 36, label: '36 per page'},
                    {value: 72, label: '72 per page'},
                ],
                cssClasses: {
                    select: 'form-control custom-select',
                }
            })
        );
    }

    search.addWidget(
        pagination({
            container: '#pagination',
            cssClasses: {
                root: 'pagination',
                item: 'page-item',
                link: 'page-link',
                active: 'active'

            }
        })
    );

    // search.addWidget({
    //     init: function (opts) {
    //         const helper = opts.helper;
    //         const input = document.querySelector('#searchBox');
    //         input.addEventListener('input', function (e) {
    //             helper.setQuery(e.currentTarget.value) // update the parameters
    //                 .search(); // launch the query
    //         });
    //     }
    // });
    search.addWidget({
        render: function (data) {
            const $hits = [];

            data.results.hits.forEach(function (hit) {
                let padding = 50;
                console.log('hit', hit);

                if (hit.assetCategory && hit.assetCategory.length) {
                    hit.eventCat = hit.assetCategory[0].title;
                } else if (hit.assetTypes && hit.assetTypes.length) {
                    if (hit.assetTypes[0]['slug'] === 'audio') {
                        hit.eventCat = "Radio"
                    } else {
                        hit.eventCat = hit.assetTypes[0].title;
                    }
                } else {
                    hit.eventCat = 'UNKNOWN';
                }
                if (hit.thumbnails) {
                    padding = hit.thumbnails.small.height / hit.thumbnails.small.width * 100;
                }
                let layout = algoliaLayout;
                let $hitHtml = "";
                if (layout === 'audioFiles') {
                    $hitHtml = "<div class='card gallery-item gallery-item-audio'><div class='card-body'>";
                    $hitHtml += "<h4>" + hit.title + "</h4>";
                    $hitHtml += "<div class='gallery-item-audio-content'>";
                    $hitHtml += "<div class='audio-player'><audio src='" + hit.url + "' controls></audio></div>";
                    $hitHtml += "<div class='audio-actions'>";
                    $hitHtml += "<a class='btn btn-primary track-event' data-event-action='download' data-event-category='" + hit.eventCat + "' data-event-label='" + hit.title + "' href='/download/" + hit.downloadToken + "'><i class='fas fa-download'></i> Download</a></div>";
                    $hitHtml += "</div>";
                    $hitHtml += "</div>";
                    $hitHtml += "</div>";
                }



                if (layout === 'list') {
                    // console.log('LIST');
                    let $actionHtml = "";
                    let sizesOutput = "";
                    if (hit.sizes && hit.sizes.length) {
                        sizesOutput = '<p class="ais-hit--size">' + hit.sizes[0]["title"] + '</p>';
                    }
                    if (!hit.summary) {
                        hit.summary = "";
                    }
                    let assetTypeString = null;
                    if (algoliaShowAssetTypeLabels) {
                        if (hit.assetType) {
                            assetTypeString = _.map(hit.assetType, 'title').join(', ');
                            console.log('assetTypeString', assetTypeString);
                        } else {
                            assetTypeString = hit.contentType.name;
                            console.log('assetTypeString', assetTypeString);
                        }
                    }
                    let dateCreated = moment(hit.timestamp * 1000);
                    console.log('dateCreated', dateCreated);
                    let href = hit.uri;
                    if (hit.downloadToken) {
                        href = '/download/' + hit.downloadToken;
                    }
                    $hitHtml =
                        '<a href="' + href + '" class="card mb-3 track-event" data-event-action="download" data-event-category="' + hit.eventCat + '" data-event-label="' + hit.title + '"  xstyle="padding-bottom: ' + padding + '%">' +
                        '<div class="card-body">' +
                        '<div class="media">' +
                        (hit.imagePath ? '<img class="img-fluid mr-3" src="https://platformb-sdbeef.imgix.net/' + hit.imagePath + '?blur=200&px=16" data-src="https://platformb-sdbeef.imgix.net/' + hit.imagePath + '?w=150&h=150" alt="">' : '') +
                        '<div class="media-body">' +
                        '<h4 class="ais-hit--title">' + hit.title + '</h4>' +
                        '<div class="entry-meta">' +
                        '<p>Created ' + dateCreated.fromNow() + '</p>'+
                        '</div>' +
                        (hit.downloadToken ? '<button class="btn btn-primary"><i class="fas fa-download"></i> Download</button>' : '') +
                        '<p>' + hit.summary + '</p>' +
                        '</div>' +
                        '</div>' +
                        (assetTypeString ? '<div class="card-label">' + assetTypeString + '</div>' : '') +

                        '</div>' +

                        '</a>';
                    if (hit.downloadToken) {

                    } else {

                    }

                }

                if (layout === 'cards') {
                    // console.log('CARDS');
                    let $actionHtml = "";
                    let sizesOutput = "";
                    // if (hit.sizes && hit.sizes.length) {
                    //     sizesOutput = '<p class="ais-hit--size">' + hit.sizes[0]["title"] + '</p>';
                    // }
                    $hitHtml =
                        '<a href="' + hit.uri + '" class="card card-sm card-dark" xstyle="padding-bottom: ' + padding + '%">' +
                            '<div class="card-body">' +
                                '<div class="card-img-fill">' +
                                    '<div class="card-img-fill-inner">' ;

                                if(hit.contentType.handle === 'radio' && !hit.imagePath){
                                        $hitHtml = $hitHtml +  '<i class="fas fa-volume-up fa-w-18 fa-9x"></i>';

                                }else{
                                      $hitHtml = $hitHtml +  '<img class="img-fluid" src="https://platformb-sdbeef.imgix.net/' + hit.imagePath + '?blur=200&px=16" data-src="https://platformb-sdbeef.imgix.net/' + hit.imagePath + '?w=600&h=600" alt="">';
                                }

                                $hitHtml = $hitHtml +  '</div>' +
                                '</div>' +
                                '<div class="item-actions">' +
                                    $actionHtml +
                                '</div>' +


                            '</div>' +
                        '<div class="item-footer card-footer">' +
                        '<h4 class="ais-hit--title">' + hit.title + '</h4>' +
                        sizesOutput +
                        '</div>' +
                        '</a>';
                }
                if (layout === 'justifiedGallery') {
                    // console.log("Justified!");
                    // console.log(hit);
                    let $actionHtml = "";
                    if (hit.contentType.handle === 'images') {
                        $actionHtml = "<a class='lightbox track-event' data-event-action='preview' data-event-category='" + hit.eventCat + "' data-event-label='" + hit.title + "' href='https://platformb-sdbeef.imgix.net/" + hit.imagePath + "?w=800&h=800&auto=format'>" +
                            '<i class="fas fa-search-plus"></i>' +
                            '</a>' +
                            "<a class='track-event' data-event-action='download' data-event-category='" + hit.eventCat + "' data-event-label='" + hit.title + "' href='/download/" + hit.downloadToken + "'>" +
                            '<i class="fas fa-download"></i>' +
                            '</a>';
                    } else if (hit.contentType.handle === 'myImages') {
                        $actionHtml = "<a class='lightbox track-event' data-event-action='preview' data-event-category='" + hit.eventCat + "' data-event-label='" + hit.title + "' href='https://platformb-sdbeef.imgix.net/" + hit.imagePath + "?w=800&h=800&auto=format'>" +
                            '<i class="fas fa-search-plus"></i>' +
                            '</a>' +
                            "<a class='track-event' data-event-action='download' data-event-category='" + hit.eventCat + "' data-event-label='" + hit.title + "' href='/download/" + hit.downloadToken + "'>" +
                            '<i class="fas fa-download"></i>' +
                            '</a>'+
                            "<a class='dont-track-event' data-event-action='view' data-event-category='" + hit.eventCat + "' data-event-label='" + hit.title + "' href='/" + hit.uri + "'>" +
                            '<i class="fas fa-info-circle"></i>' +
                            '</a>';


                    } else if (hit.contentType.handle === 'audio') {
                        $actionHtml = '<a href="/download/' + hit.downloadToken + '">' +
                            '<i class="fas fa-download"></i>' +
                            '</a>';
                    } else if (hit.contentType.handle === 'videos') {
                        $actionHtml = '<a href="/' + hit.uri + '">' +
                            '<i class="fa fa-link"></i>' +
                            '</a>';
                    } else if (hit.contentType.handle === 'assetBundles') {
                        $actionHtml = '<a href="/' + hit.uri + '">' +
                            '<i class="fas fa-binoculars"></i>' +
                            '</a>';
                    } else {
                        $actionHtml = '<a class="lightbox" href="https://platformb-sdbeef.imgix.net/' + hit.imagePath + '?w=800&h=800&auto=format">' +
                            '<i class="fas fa-search-plus"></i>' +
                            '</a>' + '<a href="/' + hit.uri + '">' +
                            '<i class="fas fa-cogs"></i>' +
                            '</a>';
                    }
                    if (hit.contentType.handle === 'audio') {


                    } else {

                        $hitHtml =
                            '<div class="gallery-item" xstyle="padding-bottom: ' + padding + '%">' +
                            '<img class="img-fluid" src="https://platformb-sdbeef.imgix.net/' + hit.imagePath + '?blur=200&px=16" data-src="https://platformb-sdbeef.imgix.net/' + hit.imagePath + '?w=&00h=600" alt="">' +
                            '<div class="item-actions">' +
                            $actionHtml +

                            '</div>' +

                            '<div class="item-footer">' +
                            '<h4 class="ais-hit--title mb-0">' + hit.title + '</h4>';
                        // $hitHtml += '<a href="/download/' + hit.downloadtoken + '" target="_blank" class="btn btn-sm btn-block btn-primary">Download</a>';
                        $hitHtml += '</div>' +
                            '</div>';
                    }

                }
                if (layout === 'bricks') {
                    $hitHtml =
                        '<div class="gallery-item-wrap">' +
                            '<div class="gallery-item" style="padding-bottom: ' + padding + '%">' +
                                '<a class="lightbox" href="' + hit.url + '">' + '<img class="img-fluid" src="https://platformb-sdbeef.imgix.net/' + 'image-vault/ZD1211-72_StudioLeft_L_bw.jpg' + '" alt="">' + '</a>' +

                                '<div class="item-footer">' +
                                    '<h4 class="ais-hit--title mb-0">' + hit.title + '</h4>';
                                    // $hitHtml += '<a href="/download/' + hit.downloadtoken + '" target="_blank" class="btn btn-sm btn-block btn-primary">Download</a>';
                                $hitHtml +='</div>' +
                            '</div>' +
                        '</div>';
                }


                let $hit = $($hitHtml);
                // var lightboxLink = $hit.find('a.lightbox');
                // console.log('lightboxLink', lightboxLink);
                // new Luminous(lightboxLink[0]);
                // $hit.click(function () {
                //     console.log(hit, 'clicked');
                // });
                $hits.push($hit);
            });
            $('#hits').html($hits);
            if (!data.results.hits.length) {
                $('#hits').html('<div class="alert alert-info">Your search did not yield any results</div>');
            }
            imgSwap();

            const sizes = [
                {columns: 2, gutter: 10},
                {mq: "600px", columns: 3, gutter: 10},
                {
                    mq: "800px",
                    columns: 3,
                    gutter: 10
                },
                // {mq: "1000px", columns: 5, gutter: 10},
                // {mq: "1130px", columns: 6, gutter: 12}
            ];
            // const instance = Bricks({
            //     container: '.gallery.bricks',
            //     packed: 'data-packed',
            //     sizes: sizes
            // });
            //
            // // instance.pack();
            // instance.pack();


            if(algoliaLayout === 'justifiedGallery'){
                $("#hits").justifiedGallery({
                    rowHeight: 200,
                    lastRow: 'nojustify',
                    margins: 3
                }).on('jg.complete', function () {
                    baguetteBox.run('.gallery');
                });
            }

            setupGaTracking();


        },
        templates: {
            empty: '<p class="info">No results were found with your current filters. Try to remove some filters or change the search query.</p>',
        },
    });
    // search.addWidget(
    //     hits({
    //         container: '#hits',
    //         templates: {
    //             empty: 'No results',
    //             item: document.getElementById('hit-template').innerHTML,
    //
    //         },
    //
    //
    //     })
    // );


    search.start();
    var demoTrigger = document.querySelector('.lightbox');
    if (demoTrigger) {

        new Luminous(demoTrigger);
    }
}

function imgSwap() {
    var imgDefer = document.getElementsByTagName('img');
    for (var i = 0; i < imgDefer.length; i++) {
        if (imgDefer[i].getAttribute('data-src')) {
            imgDefer[i].setAttribute('src', imgDefer[i].getAttribute('data-src'));
        }
    }
}

window.onload = imgSwap;
